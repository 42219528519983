<template>
  <div class="container mx-auto py-16">
    <h1 class="z-10 mb-16 mb-4 text-center font-merri text-4xl font-semibold">
      FAQs
    </h1>
    <h2 class="text-center">
      Some questions other cats have been asking about:
    </h2>

    <div class="mx-auto mt-8 w-full px-4 sm:w-2/3 sm:px-0">
      <h3 class="mb-4 mt-8 text-xl font-semibold">
        Tell me more about the company.
      </h3>

      <p class="mb-4">
        We are a UK company, based in Cheshire and develop all our own recipes.
        We are 100% independently owned small business. We have no involvement
        with any big multinationals and answer only to all the cool cats we're
        fortunate to serve.
      </p>

      <h3 class="mb-4 mt-8 text-xl font-semibold">
        Can I tailor my order to only the flavours I want?
      </h3>

      <p class="mb-4">
        We offer a wide range of different textures and flavours so you can Pick
        & Mix exactly what you like. You can order just 1 flavour or a mix of
        all {{ $store.getters.foodProductsCount || 32 }} different options if
        preferred! You can have pouches on their own or mix pouches/trays/cans.
        In essence, we're all about offering flexibility, so you can change as
        often as you like and create the ideal order for your cat.
      </p>

      <h3 class="mb-4 mt-8 text-xl font-semibold">
        Can I set my payment to go out on a set date every month (e.g. after
        payday)?
      </h3>

      <p class="mb-4">
        Yes once you're set up with your subscription, you can easily choose for
        your payment to go out on a set date every month. Simply press ‘Change
        Payment Date' in your account area to choose any date in the month (from
        1st-28th).
      </p>
      <h3 class="mb-4 mt-8 text-xl font-semibold">
        Can I adjust my subscription anytime?
      </h3>

      <p class="mb-4">
        Sure can. We actively encourage you to change your box as often as you
        like to keep your cat happy with their food choices. You can adjust the
        quantity or the food selection in you box anytime up until your payment
        is processed for your next box. After this date, which is visible in
        your account area, any changes will be reflected in your next box. We
        think we've made it really easy to do but if you have any problems,
        we're right here for you!
      </p>

      <h3 class="mb-4 mt-8 text-xl font-semibold">
        Can I cancel my subscription anytime?
      </h3>

      <p class="mb-4">
        Absolutely. We put you in full control and make it really easy to do. We
        hate companies that make it difficult to cancel. Simply press the cancel
        button in your account area. You can cancel anytime up until your
        payment is processed for your next box.
      </p>

      <h3 class="mb-4 mt-8 text-xl font-semibold">Where is your food made?</h3>

      <p class="mb-4">
        To offer a wide range of textures to keep fussy felines happy, our
        recipes are manufactured by specialist suppliers in the UK, Europe and
        Thailand. All our products are made to a high standard in fully
        certified manufacturing facilities and produced in accordance with
        FEDIAF regulations. All ingredients can be traced from the source
        through the manufacturing process. We also strictly only use
        Dolphin-Friendly Tuna which is naturally found in the warmer waters
        close to Thailand.
      </p>
      <p class="mb-4">
        We would love to produce more here, however there are virtually no
        manufacturers in the UK for most of the textures we require. The cost to
        set up full manufacturing facilities here is very high but is part of
        our long term plan as we grow.
      </p>

      <h3 class="mb-4 mt-8 text-xl font-semibold">
        Why do you donate food to charity instead of money?
      </h3>

      <p class="mb-4">
        With food we know exactly where it's going, directly to benefit our less
        fortunate fellow cats. We've noticed some charities spend a high amount
        on certain aspects, political campaigns etc. whilst we aren't against
        this, it just feels better to know the good will is going directly to
        the animals.
      </p>
      <h3 class="mb-4 mt-8 text-xl font-semibold">
        Do you ever do any discounts?
      </h3>
      <p class="mb-4">
        We work really hard to make our prices as affordable as we can, keeping
        them low all year round, so there simply isn't room for any further
        discounts at any time. We also only ever review our prices in January so
        there are no nasty shocks during the year!
      </p>

      <h3 class="mb-4 mt-8 text-xl font-semibold">
        As this is premium food, how do you avoid the usual high cost?
      </h3>
      <p class="mb-4">
        Premium food has far superior ingredients which cost significantly more
        to use. However as we supply direct, we don't have to give a large chunk
        to supermarkets, meaning we can pass these savings direct to your
        pocket.
      </p>

      <h3 class="mb-4 mt-8 text-xl font-semibold">
        What are Cool cats doing for the environment?
      </h3>
      <p class="mb-4">
        We're constantly looking at how we can do everything possible for the
        environment, here are a few areas we have addressed already;
      </p>

      <div class="mb-2 flex">
        <font-awesome-icon
          :icon="['far', 'check']"
          class="mr-4 mt-1 text-xl"
          :class="`text-gray-600`"
        />
        Recyclable aluminium Trays and Cans for wet food
      </div>
      <div class="mb-2 flex">
        <font-awesome-icon
          :icon="['far', 'check']"
          class="mr-4 mt-1 text-xl"
          :class="`text-gray-600`"
        />
        <div>
          100% compostable dry food bags <br />
          <div class="italic">
            (We found many dry food bags are made from non-recyclable plastic)
          </div>
        </div>
      </div>
      <div class="mb-2 flex">
        <font-awesome-icon
          :icon="['far', 'check']"
          class="mr-4 mt-1 text-xl"
          :class="`text-gray-600`"
        />
        Fully recyclable delivery boxes &amp; recycled paper packaging
      </div>
      <div class="flex">
        <font-awesome-icon
          :icon="['far', 'check']"
          class="mr-4 mt-1 text-xl"
          :class="`text-gray-600`"
        />
        Re-use and refill cardboard containers for storing pouches, trays etc
      </div>
      <p class="mb-4 mt-8">
        We believe we all have a duty to the environment, cool cats included!
      </p>
      <h3 class="mb-4 mt-8 text-xl font-semibold">
        Do you deliver to Northern Ireland, Scottish Highlands etc?
      </h3>

      <p class="mb-4">
        We're pleased to say we deliver to Northern Ireland, Scottish Highlands
        and the Isle of Wight. Unfortunately we can't deliver to the Channel
        Islands at this stage. For our monthly boxes, in order to send to these
        areas, we charge £2.99 to cover the extra delivery costs we incur.
        Please be aware it can take slightly longer to deliver to these areas.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'FAQs'
  }
};
</script>

<style lang="css" scoped></style>
